// same in churnkey-embed
export const generateStyleClassesFromOrgBranding = (orgBranding) => {
  if (window.ckOrgBrandingStyle) {
    if (!orgBranding?.primaryColor) {
      return null;
    }
    window.ckOrgBrandingStyle.remove();
  }
  const primary = orgBranding.primaryColor || '#3c5ccf';
  const primaryLight = `${primary}11`;
  const primaryMiddle = `${primary}80`;

  const cssText = `
    .ck-style { --ckPrimaryColor: ${primary}}
    .ck-style { --tw-ring-color: ${primary}!important}
    .ck-style .border-client-primary { border-color: ${primary} }
    .ck-style .text-client-primary { color: ${primary} }
    .ck-style .border-text-client-primary { color: ${primary} }
    .ck-style .bg-client-primary { background-color: ${primary} }
    .ck-style .bg-client-primary-light { background-color: ${primaryLight} }
    .ck-style .text-client-primary-middle { color: ${primaryMiddle} }
    .ck-style .box-shadow-client-primary { box-shadow: 3px 10px 20px 0px ${primary}4d }
    .ck-style [type='text']:focus, .ck-style [type='email']:focus, .ck-style [type='url']:focus, .ck-style [type='password']:focus, .ck-style [type='number']:focus, .ck-style [type='date']:focus, .ck-style [type='datetime-local']:focus, .ck-style [type='month']:focus, .ck-style [type='search']:focus, .ck-style [type='tel']:focus, .ck-style [type='time']:focus, .ck-style [type='week']:focus, .ck-style [multiple]:focus, .ck-style textarea:focus, .ck-style select:focus {
      --tw-ring-color: ${primary}; 
      border-color: ${primary};
    }
    `;

  window.ckOrgBrandingStyle = document.createElement('style');
  window.ckOrgBrandingStyle.innerHTML = cssText;
  document.getElementsByTagName('head')[0].appendChild(window.ckOrgBrandingStyle);
  return cssText;
};
