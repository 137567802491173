<template>
  <div class="flex flex-col min-h-screen items-center bg-brand-light-sand py-12">
    <div class="w-full max-w-xl flex-grow flex flex-col justify-center items-center space-y-10 text-center">
      <BrandImage :src="companyLogo" alignment="center" v-if="companyLogo" />
      <div class="bg-white rounded-lg border-2 border-brand-sand p-10 text-center">
        <h1 class="text-black text-xl font-semibold mb-2">{{ errorMessage }}</h1>
        <p class="text-md text-brand-medium-brown leading-relaxed">
          Please contact us if you need help with your account.
        </p>
        <a :href="hasAccount ? redirectLink : 'https://churnkey.co'" class="mt-10 w-full inline-block px-4 py-2 bg-brand-blue text-white rounded">
          {{ redirectButtonText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BrandImage from '@/components/BrandImage.vue';

export default {
  name: 'error',
  components: {
    BrandImage,
  },
  data() {
    return {};
  },
  computed: {
    errorContext() {
      return this.$store.state.account;
    },
    companyName() {
      return this.errorContext?.name;
    },
    companyLogo() {
      return this.errorContext?.branding?.brandImage;
    },
    redirectButtonText() {
      return this.companyName ? `Continue to ${this.companyName}` : 'Contact Support';
    },
    errorMessage() {
      const defaultMessage = 'Sorry, something went wrong preparing your offer.';
      if (!this.$store.state?.errorMessage) return defaultMessage;
      return this.$store.state?.errorMessage;
    },
    errorCode() {
      return this.$store.state?.errorCode;
    },
    hasAccount() {
      return !!this.errorContext?.name;
    },
    redirectLink() {
      let link = this.errorContext?.redirectLink;
      // prepend https:// if not already there
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
  },
  async created() {
    await this.$store.dispatch('FETCH_CONTEXT');
  },
};
</script>

<style lang="scss">
@import '@/assets/style.scss';
</style>
